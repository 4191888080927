import Cookies from "js-cookie"

import { COOKIE_KEYS } from "~/lib/constants"
import { canUseDom } from "~/utils/can-use-dom"

export async function getIsPreview(): Promise<boolean> {
  try {
    if (canUseDom) {
      return Boolean(Cookies.get(COOKIE_KEYS.IS_DRAFT_MODE))
    } else {
      // Getting the preview mode if enabled
      const { isEnabled: isPreviewMode } = (await import("next/headers")).draftMode()
      return isPreviewMode
    }
  } catch {
    return false
  }
}
