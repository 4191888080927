import { z } from "zod"

import { enumWithDefaultSchema } from "~/lib/zod/utils/enum-with-default"
import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { productsIntegrationFieldSchema } from "~/data/products-integration-field/schema"
import { linkSchema, sbStoryLinkSchema } from "~/components/ui/Link/_data/schema"

export const roundedCtaSchema = z.object({
  link: linkSchema,
  product: optionalTupleSchema(productsIntegrationFieldSchema).nullish(),
  material_panel: sbStoryLinkSchema.nullish(),
  label: z.string().nullish().default(null),
  obfuscate: z.coerce.boolean(),
  display_icon: z.coerce.boolean(),
  color: enumWithDefaultSchema(["white", "grey-bg-page", "blue-deep"], "grey-bg-page"),
  background: enumWithDefaultSchema(["primary", "secondary"], "primary"),
})

export type RoundedCtaFromSchema = z.infer<typeof roundedCtaSchema>
