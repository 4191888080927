import type { ISbStoryData } from "storyblok-js-client"

import type { InferReturn, Nullish } from "~/@types/generics"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { serializeMaterialPanelLink } from "~/data/material-panel-link/serializer"
import { panelMaterialsSchema } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/schema"
import serializeImage from "~/components/ui/Image/_data/serializer"

export default async function serializePanelMaterials(data: Nullish<ISbStoryData["content"]>, locale: Nullish<string>) {
  const panel = handleZodSafeParse(panelMaterialsSchema, data, { errorContext: `serializeMaterialPanels` })
  if (!panel || !panel.items) {
    return null
  }

  return {
    items: await Promise.all(
      panel.items?.map(async (item) => ({
        suptitle: item.suptitle,
        title: item.title,
        text: item.text,
        link: item.material_panel_link?.[0]
          ? await serializeMaterialPanelLink(item.material_panel_link?.[0], locale)
          : null,
        image: serializeImage(item.image),
      }))
    ),
  }
}

export type TPanelMaterials = InferReturn<typeof serializePanelMaterials>
