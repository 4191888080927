import type { TShopTheLookSchema } from "~/components/ui/ShopTheLookPanel/_data/schema"

export type TShopTheLook = ReturnType<typeof serializeSBShopTheLook>

export function serializeSBShopTheLook({ products }: TShopTheLookSchema) {
  return products
    ?.map((product) => {
      switch (product.component) {
        case "shopify_product_with_variant":
          if (!product.data?.data?.product || !product.data?.data.variant) return null

          return {
            __typename: "ShopifyProductWithVariant",
            id: product.data?.data.product.id,
            variantId: product.data?.data.variant.id,
          } as const

        case "shopify_product_bundle_with_variant":
          if (
            !product.master_product?.data?.product ||
            !product.product_bundle?.data?.product ||
            !product.product_bundle?.data?.variant
          )
            return null

          return {
            __typename: "ShopifyProductBundleWithVariant",
            id: product.master_product?.data.product.id,
            bundleId: product.product_bundle?.data?.product.id,
            bundleVariantId: product.product_bundle?.data?.variant.id,
          } as const
      }
    })
    .filter(Boolean)
}
