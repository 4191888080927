import type { InferReturn, Nullish } from "~/@types/generics"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { fetchPanelMaterials } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/get"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import type { RoundedCtaFromSchema } from "~/components/ui/RoundedCta/_data/schema"

export default async function serializeRoundedCta(roundedCta: Nullish<RoundedCtaFromSchema>, locale: Nullish<string>) {
  const productId = roundedCta?.product?.[0]?.product_with_variant?.data?.product?.id
  let productLink = null

  // Get product reference if it exists
  if (productId) {
    const { product } = await getSfProduct({ id: productId, locale })

    const parsedLink = handleZodSafeParse(linkSchema, product, {
      errorContext: `RoundedCta Product Link: ${product?.handle}`,
    })

    productLink = parsedLink ? serializeLink(parsedLink, locale, Boolean(roundedCta?.obfuscate)) : null

    return roundedCta
      ? {
          children: roundedCta.label,
          displayIcon: roundedCta.display_icon,
          theme: roundedCta.color,
          type: roundedCta.background,
          ...(productLink ?? {}),
        }
      : null
  }

  if (roundedCta?.material_panel?.story?.slug) {
    const materials = await fetchPanelMaterials(locale, roundedCta.material_panel.story.slug)

    return {
      children: roundedCta.label,
      displayIcon: roundedCta.display_icon,
      theme: roundedCta.color,
      type: roundedCta.background,
      materialPanels: materials,
    }
  }

  return serializeRoundedCtaBase(roundedCta, locale)
}

export function serializeRoundedCtaBase(roundedCta: Nullish<RoundedCtaFromSchema>, locale: Nullish<string>) {
  return roundedCta
    ? {
        children: roundedCta.label,
        displayIcon: roundedCta.display_icon,
        theme: roundedCta.color,
        type: roundedCta.background,
        ...(serializeLink(roundedCta.link, locale, roundedCta.obfuscate) ?? {}),
      }
    : null
}

export type TRoundedCta = InferReturn<typeof serializeRoundedCta>
