import type { UnlikelyVideo } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getSfMedia } from "~/lib/shopify/queries/get-sf-media"
import { isUnlikelyVideo } from "~/lib/shopify/serializers/serialize-shopify-media"

export default async function getSfMediaVideo(videoId: Nullish<string>): Promise<Nullish<UnlikelyVideo>> {
  const video = videoId ? (await getSfMedia({ withLocale: false, id: videoId })).media : null

  return video && isUnlikelyVideo(video) ? video : null
}
