import { z } from "zod"

import { gidSchema } from "~/lib/zod/utils/gid-schema"

export const sbIntegrationFieldProductSchema = z.object({ data: z.object({ id: gidSchema }).nullish() }).nullish()
export const sbIntegrationFieldProductWithVariantSchema = z
  .object({
    data: z
      .object({ product: z.object({ id: gidSchema }).nullish(), variant: z.object({ id: gidSchema }).nullish() })
      .nullish(),
  })
  .nullish()
