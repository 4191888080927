import { z } from "zod"

import { sbPluginIntegrationShopifyVideos } from "~/lib/storyblok/schemas/plugins/integration-shopify-data-field"
import { enumWithDefaultSchema } from "~/lib/zod/utils/enum-with-default"
import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { sbAssetSchema } from "~/components/ui/Asset/_data/schema"
import { richtextSchema } from "~/components/ui/RichText/_data/schema"
import { roundedCtaSchema } from "~/components/ui/RoundedCta/_data/schema"

export const heroCardSharedContentSchema = z.object({
  suptitle: richtextSchema.nullish(),
  subtitle: richtextSchema.nullish(),
  title: richtextSchema.optional(),
  asset: optionalTupleSchema(sbAssetSchema).nullish(),
  cta: optionalTupleSchema(roundedCtaSchema).nullish(),
  text_color: enumWithDefaultSchema(["light", "dark"], "light"),
  videos: optionalTupleSchema(sbPluginIntegrationShopifyVideos).nullish(),
})

export type HeroCardSharedContentSchema = z.infer<typeof heroCardSharedContentSchema>
