import { z } from "zod"

import {
  UnlikelyMediaType,
  type UnlikelyImage,
  type UnlikelyMedia,
  type UnlikelyVideo,
} from "@unlikelystudio/commerce-connector"

import { serializeShopifyImages, type TImage } from "~/components/ui/Image/_data/serializer"
import type { TVideo } from "~/components/ui/Video"
import { serializeShopifyVideos } from "~/components/ui/Video/_data/serializer"

export const shopifyMediaSchema = z.object({
  url: z.string(),
  altText: z.string().nullish(),
  width: z.number(),
  height: z.number(),
  id: z.string(),
  mediaContentType: z.enum(["IMAGE", "VIDEO"]),
  createdAt: z.any(),
})

export type ShopifyMediaFromSchema = z.infer<typeof shopifyMediaSchema>

type TMediaImage = TImage & { type: "image" }
type TMediaVideo = TVideo & { type: "video" }
export type TMedia = (TMediaImage | TMediaVideo)[]

export function serializeShopifyMedia(media: UnlikelyMedia[]) {
  return media?.map((item) => {
    if (!item) return null

    if (isUnlikelyImage(item)) {
      const images = serializeShopifyImages([item])
      const processedImage = images.filter(
        (item) => item.src.indexOf(".png") > -1 || item.src.indexOf(".jpg") > -1
      )?.[0]

      return processedImage
        ? ({
            ...processedImage,
            type: "image",
          } as const)
        : null
    }

    if (isUnlikelyVideo(item)) {
      const processedVideo = serializeShopifyVideos([item])?.[0]

      return processedVideo
        ? ({
            ...processedVideo,
            type: "video",
          } as const)
        : null
    }
  })
}

export function isUnlikelyVideo(media: UnlikelyMedia): media is UnlikelyVideo {
  return media?.mediaContentType === UnlikelyMediaType.Video
}

export function isUnlikelyImage(media: UnlikelyMedia): media is UnlikelyImage {
  return media?.mediaContentType === UnlikelyMediaType.Image
}

export function getMediaName(media: TMedia[number]) {
  return media.src?.split("/")?.at(-1)?.split(".")[0]
}
