import type { InferReturn } from "~/@types/generics"
import type { SbAssetFromSchema } from "~/components/ui/Asset/_data/schema"
import serializeImage from "~/components/ui/Image/_data/serializer"
import { serializeSBShopTheLook } from "~/components/ui/ShopTheLookPanel/_data/serializer"
import { type LoaderParams } from "~/components/abstracts/Image/loader"

export type TSbAsset = Partial<InferReturn<typeof serializeSbAsset>>

export function serializeSbAsset(asset: SbAssetFromSchema, loaderParams?: LoaderParams) {
  return {
    image: asset?.image ? serializeImage(asset.image, loaderParams) : null,
    imageMobile: asset.mobile_image?.[0] ? serializeImage(asset.mobile_image[0].image, loaderParams) : null,
    shopTheLook: asset.shop_the_look?.[0] ? serializeSBShopTheLook(asset.shop_the_look?.[0]) : null,
  }
}
