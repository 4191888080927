import { z } from "zod"

import { zodFallback } from "~/lib/zod/utils/zod-fallback"

export function sbPluginIntegrationShopifyDataFieldSchema() {
  return z
    .object({
      id: z.string(),
      poster: z.object({
        url: z.string(),
        altText: z.string().nullish(),
      }),
      sources: z.array(
        z.object({
          url: z.string(),
          width: z.number(),
          height: z.number(),
        })
      ),
    })
    .or(zodFallback(null))
}

export const sbPluginIntegrationShopifyVideos = z.object({
  component: z.string(),
  video_mobile: sbPluginIntegrationShopifyDataFieldSchema(),
  video_desktop: sbPluginIntegrationShopifyDataFieldSchema(),
})

export type SbIntegrationShopifyDataField = z.infer<ReturnType<typeof sbPluginIntegrationShopifyDataFieldSchema>>
