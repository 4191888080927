import type { Nullish } from "~/@types/generics"
import getSfMediaVideo from "~/lib/shopify/queries/get-sf-media-video"
import { rTFilledOrNull } from "~/lib/storyblok/utils/check-empty-string"
import { serializeSbAsset } from "~/components/ui/Asset/_data/serializer"
import serializeRoundedCta, { serializeRoundedCtaBase } from "~/components/ui/RoundedCta/_data/serializer"
import { serializeVideo } from "~/components/ui/Video/_data/serializer"
import type { HeroCardSharedContentSchema } from "~/components/slices/Heroes/_data/schema"

export default async function serializeHeroCardSharedContent(
  heroCardContent: HeroCardSharedContentSchema,
  locale: Nullish<string>
) {
  const videoDesktop = await getSfMediaVideo(heroCardContent.videos?.[0]?.video_desktop?.id)
  const videoMobile = await getSfMediaVideo(heroCardContent.videos?.[0]?.video_mobile?.id)

  return {
    suptitle: rTFilledOrNull(heroCardContent.suptitle),
    subtitle: rTFilledOrNull(heroCardContent.subtitle),
    title: rTFilledOrNull(heroCardContent.title),
    asset: heroCardContent.asset?.[0] ? serializeSbAsset(heroCardContent.asset?.[0]) : null,
    videos: {
      desktop: serializeVideo(videoDesktop),
      mobile: serializeVideo(videoMobile),
    },
    cta: await serializeRoundedCta(heroCardContent.cta?.[0], locale),
  }
}

export function serializeHeroCardSharedContentSync(
  heroCardContent: HeroCardSharedContentSchema,
  locale: Nullish<string>
) {
  return {
    suptitle: rTFilledOrNull(heroCardContent.suptitle),
    subtitle: rTFilledOrNull(heroCardContent.subtitle),
    title: rTFilledOrNull(heroCardContent.title),
    asset: heroCardContent.asset?.[0] ? serializeSbAsset(heroCardContent.asset?.[0]) : null,
    videos: {
      desktop: null,
      mobile: null,
    },
    cta: serializeRoundedCtaBase(heroCardContent.cta?.[0], locale),
  }
}
