import { captureException } from "@sentry/nextjs"
import type { ISbStoryParams } from "storyblok-js-client"

import type { Nullish, ParseUrlParams } from "~/@types/generics"
import { COOKIE_KEYS } from "~/lib/constants"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import Storyblok from "~/lib/storyblok/client"
import type { SbSlug } from "~/lib/storyblok/constants"
import { getIsPreview } from "~/lib/storyblok/utils/get-is-preview"
import { logger } from "~/utils/logger"

type StoryOptions = Omit<ISbStoryParams, "language"> & { locale: Nullish<string> }

export async function fetchSbStory<U extends SbSlug, Params extends ParseUrlParams<U>>(
  path: U,
  { locale, ...options }: StoryOptions,
  params: Params,
  throwError?: boolean
) {
  const processedPath = getStoryPath(path, params)
  try {
    // Getting the preview mode if enabled
    const isPreviewMode = await getIsPreview()

    if (isPreviewMode) {
      options["version"] = "draft"
      const nextHeaders = await import("next/headers")
      options["from_release"] = nextHeaders.cookies().get(COOKIE_KEYS.releaseId)?.value
    }

    return await Storyblok.getStory(processedPath, {
      resolve_links: "url",
      ...options,
      language: getLang(locale),
    })
  } catch (error) {
    logger.error("fetchSbStory ERROR: ", { error, options, path, params })

    const isGlobal = processedPath.startsWith("globals/")

    if (isGlobal) {
      captureException(`Failed to fetch story global data: [${path} - ${locale}] `)
      throw new Error("Failed to fetch story global data")
    }

    if ((error as Error).message !== "Not Found") {
      captureException(error)
    }
    if (throwError) {
      throw new Error(`Failed to fetch story: ${JSON.stringify(error, null, 2)}`)
    }
  }
}

export function getStoryPath<U extends SbSlug, Params extends ParseUrlParams<U>>(path: U, params: Params) {
  return path
    .split("/")
    .map((part) => {
      if (part.startsWith(":")) {
        return params[part.slice(1) as keyof Params]
      }
      return part
    })
    .join("/")
}
