import { z } from "zod"

import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { sbIntegrationFieldProductWithVariantSchema } from "~/lib/zod/utils/sb-integration-field-schema"
import { optionalImageSchema } from "~/components/ui/Image/_data/schema"

export const productsIntegrationFieldSchema = z.object({
  component: z.literal("shopify_product"),
  product_with_variant: sbIntegrationFieldProductWithVariantSchema,
  product_bundle_color_with_variant: sbIntegrationFieldProductWithVariantSchema,
  image: optionalTupleSchema(optionalImageSchema).nullish(),
  image_hover: optionalTupleSchema(optionalImageSchema).nullish(),
  tag: z.string().nullish(),
  title: z.string().nullish(),
  subtitle: z.string().nullish(),
  hide_tag: z.coerce.boolean(),
})

export type ProductsIntegrationFieldFromSchema = z.infer<typeof productsIntegrationFieldSchema>
