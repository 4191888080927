import { z } from "zod"

import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { optionalImageSchema, sbImageSchema } from "~/components/ui/Image/_data/schema"
import { storyblokShopTheLookSchema } from "~/components/ui/ShopTheLookPanel/_data/schema"

export const sbAssetSchema = z.object({
  image: sbImageSchema.nullish(),
  mobile_image: optionalTupleSchema(optionalImageSchema).nullish(),
  shop_the_look: z.array(storyblokShopTheLookSchema).optional(),
})

export type SbAssetFromSchema = z.infer<typeof sbAssetSchema>
