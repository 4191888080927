import { z } from "zod"

import { sbIntegrationFieldProductWithVariantSchema } from "~/lib/zod/utils/sb-integration-field-schema"

export const shopifyProductWithVariantSchema = z.object({
  component: z.literal("shopify_product_with_variant"),
  data: sbIntegrationFieldProductWithVariantSchema,
})

const shopifyProductBundleWithVariantSchema = z.object({
  component: z.literal("shopify_product_bundle_with_variant"),
  master_product: sbIntegrationFieldProductWithVariantSchema,
  product_bundle: sbIntegrationFieldProductWithVariantSchema,
})

export const storyblokShopTheLookSchema = z.object({
  products: z
    .array(z.discriminatedUnion("component", [shopifyProductWithVariantSchema, shopifyProductBundleWithVariantSchema]))
    .optional(),
})

export type TShopTheLookSchema = z.infer<typeof storyblokShopTheLookSchema>
